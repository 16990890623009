<template>
    <div class="header-container">
        <!-- logo和导航 -->
        <div class="logo-nav-box">
            <div class="logo-box">
                <img src="@/assets/header/logo-4.png" alt="" />
            </div>
            <div class="nav-box">
                <ul>
                    <li><router-link to="/main">首页</router-link></li>
                    <li><router-link to="/market">市场</router-link></li>
                    <li><router-link to="/artist">艺术家</router-link></li>
                    <li><a href="http://front.nftnet.world" target="_blank">移动端入口</a></li>
                </ul>
            </div>
        </div>
        <!-- 搜索和个人中心 -->
        <div class="search-profile">
            <img src="@/assets/header/icon-search.png" alt="" />
            <img src="@/assets/header/icon-line.png" alt="" />
            <img src="@/assets/header/icon-profile.png" alt="" @click="jumpPage('personalcenter')"/>
        </div>
    </div>
</template>

<script>
export default {
    data(){

    },
    methods:{
        jumpPage(name){
            this.$router.push({
                name
            });
        }
    }
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>