import Vue from 'vue'
import App from './App.vue'

import router from './router'

// normalize 样式初始化
import 'normalize.css/normalize.css'
import '@/style/common.scss'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
