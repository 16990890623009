<template>
  <div id="app">
    <Header />

    <div class="main-wrapper">
      <router-view></router-view>
    </div>

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
.main-wrapper{
  padding-top: 96px;
}
</style>
