import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const routes = [
    {
        path:'/',
        redirect:'main',
    },
    {
        path: '/login',
        component: (resolve) => require(['@/pages/login'], resolve),
        name: 'login',
        meta: { 
            title: '登录'
        }
    },
    {
        path: '/main',
        component: (resolve) => require(['@/pages/main'], resolve),
        name: 'main',
        meta: { 
            title: '首页'
        }
    },
    {
        path: '/artist',
        component: (resolve) => require(['@/pages/artist'], resolve),
        name: 'artist',
        meta: { 
            title: '艺术家'
        }
    },
    {
        path: '/productdetail',
        component: (resolve) => require(['@/pages/productdetail'], resolve),
        name: 'productdetail',
        meta: { 
            title: '藏品详情'
        }
    },
    {
        path: '/market',
        component: (resolve) => require(['@/pages/market'], resolve),
        name: 'market',
        meta: { 
            title: '市场'
        }
    },
    {
        path: '/checkout',
        component: (resolve) => require(['@/pages/checkout'], resolve),
        name: 'checkout',
        meta: { 
            title: '确认订单'
        }
    },
    {
        path: '/personalcenter',
        component: (resolve) => require(['@/pages/personalcenter'], resolve),
        name: 'personalcenter',
        meta: { 
            title: '个人中心'
        }
    },
    // 下面是复制的同样的页面
    {
        path: '/productdetail1',
        component: (resolve) => require(['@/pages/productdetail1'], resolve),
        name: 'productdetail1',
        meta: { 
            title: '藏品详情'
        }
    },
    {
        path: '/productdetail2',
        component: (resolve) => require(['@/pages/productdetail2'], resolve),
        name: 'productdetail2',
        meta: { 
            title: '藏品详情'
        }
    },
    {
        path: '/productdetail3',
        component: (resolve) => require(['@/pages/productdetail3'], resolve),
        name: 'productdetail3',
        meta: { 
            title: '藏品详情'
        }
    },
    {
        path: '/productdetail4',
        component: (resolve) => require(['@/pages/productdetail4'], resolve),
        name: 'productdetail4',
        meta: { 
            title: '藏品详情'
        }
    },
    {
        path: '/productdetail5',
        component: (resolve) => require(['@/pages/productdetail5'], resolve),
        name: 'productdetail5',
        meta: { 
            title: '藏品详情'
        }
    },
]

export default new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes
})