<template>
    <div class="footer">
        <div class="footer-left">
            <img src="../../assets/header/logo-4.png" alt="" class="footer-logo">
            <div class="footer-title">加入创作者生态</div>
            <div class="title-aside">若您和您的团队想发行推广数字藏品，请微信扫码联系，备注来意</div>
            <img src="../../assets/footer/qrcode.png" alt="" class="qrcode">
            <div class="footer-line"></div>
            <div class="copyright"><a target="_blank" href="https://beian.miit.gov.cn/">苏ICP备2022014814号</a></div>
            <div class="copyright"><a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32010502010526">苏公网安备32010502010526号</a></div>
            <div class="copyright"><a target="_blank" href="https://bcbeian.ifcert.cn/">苏网信备32010522107425860010号</a></div>
            <div class="company">©2022 南京链集信息科技有限公司</div>
            <!-- <div class="address">地址：南京市建邺区贤坤路5号3幢江岛智立方H座2楼201-3室（云想沃客）</div> -->
        </div>
        <div class="footer-right">
            <ul>
                <li><router-link to="/login">联系我们</router-link></li>
                <li><router-link to="/login">商务合作</router-link></li>
                <li><router-link to="/login">关于我们</router-link></li>
                <li><router-link to="/login">用户协议</router-link></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" src="./index.scss" scoped>

</style>